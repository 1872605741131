import React from 'react'
import Navbar from './Navbar'
import Cv from '../assets/cv/cv-marco-dev.pdf'
import { CalculateAge } from '../helpers/calculateAge'
import { Link } from 'react-scroll'

const Hero = () => {
    return (
        <section
            name="home"
            className="bg-gray-800 text-gray-100 h-screen w-screen flex justify-center items-center flex-col relative"
        >
            <div className="mx-auto flex flex-col items-center px-4 py-16 text-center max-w-[1100px]">
                <h1 className="text-4xl font-bold leading-none sm:text-8xl">
                    this.is(
                    <span className="text-violet-400">Marco, Colia</span>)
                </h1>
                <p className="text-2xl md:text-3xl px-8 mt-8 mb-12 max-w-4xl">
                    Ho {CalculateAge(new Date(1985, 12, 16))} anni e sono
                    appassionato di tecnologia e programmazione, da qualche anno
                    ho deciso di fare della mia passione la mia attività
                    lavorativa.
                </p>
                <div className="flex flex-wrap justify-center">
                    <Link to="contatti" smooth={true} duration={900}>
                        <button className="px-8 py-3 m-2 text-lg font-semibold rounded bg-violet-400 text-gray-900">
                            Contattami
                        </button>
                    </Link>
                    <a href={Cv} target="_blank" rel="noreferrer">
                        <button className="px-8 py-3 m-2 text-lg border rounded text-gray-50 border-gray-700">
                            Curriculum Vitae
                        </button>
                    </a>
                </div>
            </div>
            <div className="mx-auto fixed z-10 bottom-0 flex flex-col justify-center items-center">
                <Navbar />
            </div>
        </section>
    )
}

export default Hero
