import React from 'react'

const PortfolioSvgIcon = () => {
    return (
        <svg
            className="mr-4 animate-pulse"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 512 512"
        >
            <path
                fill="#fff"
                d="M80 408h24a8 8 0 008-8V304a8 8 0 00-8-8H80a8 8 0 00-8 8v96A8 8 0 0080 408zm8-96h8v80H88zM128 408h24a8 8 0 008-8V328a8 8 0 00-8-8H128a8 8 0 00-8 8v72A8 8 0 00128 408zm8-72h8v56h-8zM176 408h24a8 8 0 008-8V352a8 8 0 00-8-8H176a8 8 0 00-8 8v48A8 8 0 00176 408zm8-48h8v32h-8z"
            />
            <path
                fill="#fff"
                d="M56,432H216a8,8,0,0,0,0-16H64V288a8,8,0,0,0-16,0V424A8,8,0,0,0,56,432Z"
            />
            <path
                fill="#fff"
                d="M496,104H392V88a8,8,0,0,0-8-8h-8V64A56.062,56.062,0,0,0,320,8H192a56.062,56.062,0,0,0-56,56V80h-8a8,8,0,0,0-8,8v16H16a8,8,0,0,0-8,8V256a8,8,0,0,0,8,8h8V496a8,8,0,0,0,8,8H480a8,8,0,0,0,8-8V264h8a8,8,0,0,0,8-8V112A8,8,0,0,0,496,104ZM192,80h-8V64a8.011,8.011,0,0,1,8-8H320a8.011,8.011,0,0,1,8,8V80h-8a8,8,0,0,0-8,8v16H200V88A8,8,0,0,0,192,80ZM328,96h48v8H328ZM152,64a40.04,40.04,0,0,1,40-40H320a40.04,40.04,0,0,1,40,40V80H344V64a24.032,24.032,0,0,0-24-24H192a24.032,24.032,0,0,0-24,24V80H152ZM136,96h48v8H136ZM472,488H40V264H200v24a8,8,0,0,0,8,8h96a8,8,0,0,0,8-8V264H472ZM216,280V232h80v48Zm272-32H312V224a8,8,0,0,0-8-8H208a8,8,0,0,0-8,8v24H24V120H488Z"
            />
            <path
                fill="#fff"
                d="M392 288a72 72 0 1072 72A72.083 72.083 0 00392 288zm8 16.58A56.11 56.11 0 01447.42 352H400zM336 360a56.086 56.086 0 0148-55.42v52.11l-36.83 36.83A55.708 55.708 0 01336 360zm56 56a55.708 55.708 0 01-33.52-11.17L395.31 368h52.11A56.086 56.086 0 01392 416zM240 432h64a8 8 0 000-16H240a8 8 0 000 16zM264 408h40a8 8 0 000-16H264a8 8 0 000 16z"
            />
            <path
                fill="#fff"
                d="M448,440H336a8,8,0,0,0-5.657,2.343L308.686,464H56a8,8,0,0,0,0,16H312a8,8,0,0,0,5.657-2.343L339.314,456H448a8,8,0,0,0,0-16Z"
            />
        </svg>
    )
}

export default PortfolioSvgIcon
