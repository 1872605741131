import React from 'react'

const EmailSvgIcon = () => {
    return (
        <svg
            className="mr-4 animate-pulse"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            data-name="Layer 1"
            viewBox="0 0 64 64"
        >
            <path
                fill="#fff"
                stroke="#222"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M3,25.67v30a4,4,0,0,0,4,4H57a4,4,0,0,0,4-4v-30"
            />
            <path
                fill="#fff"
                stroke="#222"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M14.5,20.44A53,53,0,0,0,3,25.51H3L29.73,43.94a4,4,0,0,0,4.54,0L61,25.51h0a53,53,0,0,0-11.5-5.07"
            />
            <path
                fill="#fff"
                stroke="#222"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M14.5,33.44V7.3a3,3,0,0,1,3-3h29a3,3,0,0,1,3,3V33.43L34.27,43.94a4,4,0,0,1-4.54,0L14.51,33.44"
            />
            <line
                x1="41.5"
                x2="22.5"
                y1="13.19"
                y2="13.19"
                fill="none"
                stroke="#222"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
            />
            <line
                x1="32"
                x2="22.5"
                y1="28.6"
                y2="28.6"
                fill="none"
                stroke="#222"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
            />
            <line
                x1="41.5"
                x2="22.5"
                y1="20.9"
                y2="20.9"
                fill="none"
                stroke="#222"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
            />
            <line
                x1="25.18"
                x2="4.29"
                y1="40.82"
                y2="58.62"
                fill="none"
                stroke="#222"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
            />
            <line
                x1="38.77"
                x2="59.72"
                y1="40.82"
                y2="58.62"
                fill="none"
                stroke="#222"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
            />
        </svg>
    )
}

export default EmailSvgIcon
