import React from 'react'
import SendIcon from '@mui/icons-material/Send'
import Doodle from '../assets/doodle.svg'

const Contacts = () => {
    return (
        <section
            name="portfolio"
            className=" text-gray-100 w-screen flex justify-center items-center mt-16 mb-32"
        >
            <div
                name="contatti"
                className="flex flex-col justify-center items-center p-2"
            >
                <div className="flex justify-center items-center align-middle mb-12">
                    <h1 className="font-[600] text-3xl md:text-6xl">
                        <SendIcon
                            className="animate-bounce"
                            sx={{ fontSize: 60 }}
                        />{' '}
                        .Contatti
                    </h1>
                </div>
                <div className="grid max-w-screen-xl grid-cols-1 gap-8 px-8 py-16 mx-auto rounded-lg md:grid-cols-2 md:px-12 lg:px-16 xl:px-32  text-gray-100">
                    <div className="flex flex-col justify-center">
                        <div className="space-y-2 mb-8">
                            <h2 className="text-4xl font-bold leading-tight lg:text-5xl">
                                Parliamone!
                            </h2>
                            <div className="text-gray-400">
                                Per proposte di lavoro o semplicemente contatti
                                di collaborazione.
                            </div>
                        </div>
                        <img
                            src={Doodle}
                            alt="contattami"
                            className="p-6 h-52 md:h-64"
                        />
                    </div>
                    <form
                        action="https://getform.io/f/b47f0459-0ed4-4536-8815-579897a42d1c"
                        method="POST"
                        encType="multipart/form-data"
                        className="space-y-6 ng-untouched ng-pristine ng-valid"
                    >
                        <div>
                            <label htmlFor="name" className="text-sm">
                                Nome Completo
                            </label>
                            <input
                                name="fullName"
                                type="text"
                                placeholder=""
                                className="w-full p-3 rounded text-black"
                            />
                        </div>
                        <div>
                            <label htmlFor="email" className="text-sm">
                                Email
                            </label>
                            <input
                                name="email"
                                type="email"
                                className="w-full p-3 rounded text-black"
                            />
                        </div>
                        <div>
                            <label htmlFor="message" className="text-sm">
                                Messaggio
                            </label>
                            <textarea
                                name="messageText"
                                rows="3"
                                className="w-full p-3 rounded text-black"
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            className="w-full p-3 text-sm font-bold tracking-wide uppercase rounded bg-violet-400 text-white"
                        >
                            Invia Messaggio
                        </button>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Contacts
