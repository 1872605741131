import React from 'react'
import Hero from '../components/Hero'
import Portfolio from '../components/Portfolio'
import Social from '../components/Social'
import Stack from '../components/Stack'
import Contacts from '../components/Contacts'

const Home = () => {
    return (
        <div className="overflow-x-hidden">
            <Social />
            <Hero />
            <Stack />
            <Portfolio />
            <Contacts />
        </div>
    )
}

export default Home
