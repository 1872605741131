import React from 'react'
import DataObjectIcon from '@mui/icons-material/DataObject'
import PortfolioData from '../data/portfolio.json'

const Portfolio = () => {
    return (
        <section
            name="portfolio"
            className="bg-gray-800 text-gray-100 w-screen flex justify-center items-center mt-16 mb-32"
        >
            <div className="flex flex-col justify-center items-center">
                <div className="flex justify-center items-center align-middle mb-12">
                    <h1 className="font-[600] text-3xl md:text-6xl">
                        <DataObjectIcon
                            className="animate-bounce"
                            sx={{ fontSize: 60 }}
                        />{' '}
                        .Portfolio
                    </h1>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6 mx-8">
                    {PortfolioData &&
                        PortfolioData.map((project, index) => {
                            return (
                                <div
                                    key={index}
                                    className="relative max-w-xs rounded-md shadow-md bg-[#2a3647] text-gray-100 hover:scale-110 duration-500 overflow-hidden shadow-lg"
                                >
                                    <img
                                        src={project.image}
                                        alt=""
                                        className="object-cover object-center w-full rounded-t-md h-72 bg-gray-500"
                                    />
                                    <div className="flex flex-col justify-between p-6 space-y-8">
                                        <div className="space-y-2">
                                            <h2 className="text-3xl font-semibold tracking-wide">
                                                {project.title}
                                            </h2>
                                            <p className="dark:text-gray-100">
                                                {project.description}
                                            </p>
                                        </div>
                                        <div className="mt-9">
                                            <a
                                                href={project.url}
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                <button
                                                    type="button"
                                                    disabled={
                                                        project.url === '#'
                                                    }
                                                    className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md bg-violet-400 text-gray-900"
                                                >
                                                    {project.url === '#'
                                                        ? `${project.status}`
                                                        : 'Link'}
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </div>
        </section>
    )
}

export default Portfolio
