import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import ErrorPage from './pages/ErrorPage'

const App = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />} />
                {/* Ultima Route per le error Page */}
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </Router>
    )
}

export default App
