import React from 'react'

const StackSvgIcon = () => {
    return (
        <svg
            className="mr-4 animate-pulse"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
        >
            <path
                fill="#fff"
                d="M2.996 3v20h12.508v5h-3.5v1h8v-1h-3.5v-5h12.5V3H2.996zm1 1h24.008v18H3.996V4zM5.98 6v13h20.047V6H5.98zm1 1h18.047v11H6.98V7zM9 10v5h2.5v-1H10v-3h1.5v-1H9zm3.5 0v5h3v-5h-3zm4 0v5h3v-5h-3zm4 0v5H23v-1h-1.5v-1h1v-1h-1v-1H23v-1h-2.5zm-7 1h1v3h-1v-3zm4 0h1v3h-1v-3zM16 19.469a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1z"
                color="#000"
                enableBackground="accumulate"
                fontFamily="sans-serif"
                fontWeight="400"
                overflow="visible"
            />
        </svg>
    )
}

export default StackSvgIcon
