import React, { useState } from 'react'
import { Link } from 'react-scroll'
import HomeSvgIcon from '../SvgIcons/HomeSvgIcon'
import StackSvgIcon from '../SvgIcons/StackSvgIcon'
import PortfolioIcon from '../SvgIcons/PortfolioSvgIcon'
import EmailSvgIcon from '../SvgIcons/EmailSvgIcon'

const Navbar = () => {
    const [mobileMenu, setMobileMenu] = useState(false)
    const handleMobileMenu = () => setMobileMenu(!mobileMenu)

    return (
        <nav className="p-4 bg-gray-800 text-gray-100 w-screen">
            <div className="flex justify-between h-16 mx-auto md:justify-center md:space-x-8">
                <ul className="items-stretch hidden space-x-3 md:flex">
                    <li className="flex">
                        <Link to="home" smooth={true} duration={900}>
                            <p className="flex items-center mt-5 px-4 -mb-1 border-b-2 border-transparent cursor-pointer">
                                <HomeSvgIcon /> Home
                            </p>
                        </Link>
                    </li>
                    <li className="flex">
                        <Link to="stack" smooth={true} duration={900}>
                            <p className="flex items-center px-4 mt-5 -mb-1 border-b-2 border-transparent cursor-pointer">
                                <StackSvgIcon /> Stack
                            </p>
                        </Link>
                    </li>
                </ul>
                <ul className="items-stretch hidden space-x-3 md:flex">
                    <li className="flex">
                        <Link to="portfolio" smooth={true} duration={900}>
                            <p className="flex items-center px-4 mt-5 -mb-1 border-b-2 border-transparent cursor-pointer">
                                <PortfolioIcon /> Portfolio
                            </p>
                        </Link>
                    </li>
                    <li className="flex">
                        <Link to="contatti" smooth={true} duration={900}>
                            <p className="flex items-center px-4 mt-5 -mb-1 border-b-2 border-transparent cursor-pointer">
                                <EmailSvgIcon /> Contatti
                            </p>
                        </Link>
                    </li>
                </ul>
                <button
                    title="Button"
                    type="button"
                    className="p-4 md:hidden"
                    onClick={handleMobileMenu}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-6 h-6 text-gray-100"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        ></path>
                    </svg>
                </button>
            </div>
            {mobileMenu && (
                <div className="h-screen w-screen text-white flex items-center justify-center">
                    <div className="flex items-center">
                        <div>Menu</div>
                        <div>
                            <div>
                                <button
                                    title="Button"
                                    type="button"
                                    className="p-4 md:hidden"
                                    onClick={handleMobileMenu}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        className="w-6 h-6 text-gray-100"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    )
}

export default Navbar
